<template>
  <div>
    <p style="font-size: 1.3rem; color: var(--dark)">
      <b>{{ $t("company_revenue") }}</b>
    </p>

    <v-form ref="form" class="ma-6" @submit.stop.prevent="update_preferences()">
      <v-radio-group center v-model="Profile.CompanyRevenue">
        <v-radio :value="0">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("0_revenue") }}</span>
          </template>
        </v-radio>
        <v-radio :value="1">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("1mil") }}</span>
          </template>
        </v-radio>
        <v-radio :value="2">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("5mil") }}</span>
          </template>
        </v-radio>
      </v-radio-group>

      <p style="font-size: 1.3rem; color: var(--dark)">
        <b>{{ $t("industry_sector") }}</b>
      </p>
      <v-row class="mt-2" align="center">
        <v-col
          v-for="(c, i) in sectorOptions"
          :key="i"
          cols="12"
          md="6"
          class="my-0 py-0"
        >
          <v-checkbox
            class="my-0 py-0"
            v-model="c.Marked"
            :disabled="c.Disabled"
          >
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t(c.Text) }}</span>
            </template></v-checkbox
          >
        </v-col>
        <v-col cols="12" md="12" class="my-0 py-0">
          <v-checkbox
            class="my-0 py-0 mx-auto text-center"
            v-model="noPreferenceSector"
            @change="noPreferenceSectorsCheckbox_changed"
          >
            <template v-slot:label>
              <span style="color: var(--dark)">{{ $t("no_preference") }}</span>
            </template></v-checkbox
          >
        </v-col>
      </v-row>

      <p style="font-size: 1.3rem; color: var(--dark)">
        <b>{{ $t("invest_intended_question") }}</b>
      </p>
      <v-radio-group center v-model="Profile.InvestIntended">
        <v-radio :value="0">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("up_to_50k") }}</span>
          </template>
        </v-radio>
        <v-radio :value="1">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("up_to_100k") }}</span>
          </template>
        </v-radio>
        <v-radio :value="2">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("up_to_500k") }}</span>
          </template>
        </v-radio>
        <v-radio :value="3">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("up_to_1mil") }}</span>
          </template>
        </v-radio>
        <v-radio :value="4">
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("above_1mil") }}</span>
          </template>
        </v-radio>
      </v-radio-group>

      <div class="text-right mt-6">
        <v-btn color="primary" type="submit" :loading="loading" class="mr-0">{{
          $t("update_preferences")
        }}</v-btn>
      </div>
      <v-alert
        class="ma-2"
        v-if="alert_msg != null"
        :type="success ? 'success' : 'error'"
      >
        {{ $t(alert_msg) }}
      </v-alert>
    </v-form>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import ApiService from "@/services/ApiService";
export default {
  name: "PortfolioProfile",
  props: {
    Profile: Object,
  },
  components: {},
  data: () => ({
    apiService: new ApiService(),
    noPreferenceSector: false,
    loading: false,
    categories: [],
    sectorOptions: [],
    error: null,
    success: null,
    alert_msg: null,
  }),
  async created() {
    await this.get_categories();
    this.get_sector_options();
  },
  computed: {},
  methods: {
    update_preferences() {
      var result = this.$refs.form.validate();
      if (!result) {
        return;
      }
      this.loading = true;
      this.alert_msg = null;

      var stringBuilderSectors = "";
      var sectors_selected = this.sectorOptions.filter((x) => x.Marked == true);
      for (var i = 0; i < sectors_selected.length; i++) {
        stringBuilderSectors += sectors_selected[i].Id;
        if (i != sectors_selected.length - 1) {
          stringBuilderSectors += ";";
        }
      }
      this.Profile.Sectors = stringBuilderSectors;

      this.apiService
        .postRequest(`investor/update-preferences`, this.Profile)
        .then((resp) => {
          this.$emit("saved", JSON.parse(resp));
          this.success = true;
          this.alert_msg = this.$t("saved");
          this.loading = false;
        })
        .catch((error) => {
          this.success = false;
          this.alert_msg = error.body.message;
          this.loading = false;
        });
    },
    get_sector_options() {
      var i;
      var user_sectors = [];
      this.sectorOptions = [];
      if (this.Profile.Sectors) {
        user_sectors = this.Profile.Sectors.split(";");
      }
      for (i = 0; i < this.categories.length; i++) {
        this.sectorOptions.push({
          Text: this.categories[i].key,
          Id: this.categories[i].id,
          Marked: user_sectors.includes(this.categories[i].id.toString()),
          Disabled: false,
        });
      }
    },
    async get_categories() {
      await this.apiService
        .getRequest("category/list-all")
        .then((result) => {
          this.categories = result.content.filter(
            (x) => x.shownRegister === true
          );
        })
        .catch((error) => {});
    },
    noPreferenceSectorsCheckbox_changed() {
      if (this.noPreferenceSector) {
        this.sectorOptions = this.sectorOptions.map(function (x) {
          x.Marked = true;
          x.Disabled = true;
          return x;
        });
      } else {
        this.sectorOptions = this.sectorOptions.map(function (x) {
          x.Marked = false;
          x.Disabled = false;
          return x;
        });
      }
    },
  },
};
</script>
