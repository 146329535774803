<template>
  <div>
    <UserPreferences
      :editable="true"
      @btnConfirm="savePreferences"
      btnText="save"
      :User="User"
      :userType="userTypeEnum.Admin"
    />
    <div class="highlighted-companies">
      <span class="highlighted-companies-title">
        {{ $t("company_user_highlights") }}
      </span>
      <span class="highlighted-companies-subtitle">
        {{ $t("highlighted_companies_subtitle") }}
      </span>
    </div>
    <div v-if="!loading">
      <v-simple-table>
        <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th class="text-center">
              {{ $t("highlighted") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(c, i) in companies" :key="i">
            <td>{{ c.Company.Name }}</td>
            <td class="text-center">
              <v-btn
                :loading="c.Loading"
                @click="highlight_company(c)"
                color="primary"
                small
              >
                <v-icon v-if="c.IsHighlighted" small>mdi-star</v-icon>
                <v-icon v-else small>mdi-star-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-container v-else>
      <v-progress-circular
        style="margin-left: 50%"
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Portfolio from "../../../../profile/tabs/Portfolio.vue";
import UserPreferences from "@/components/dxa/userPreferences/UserPreferences.vue";
import { UserTypeEnum } from "@/shared/enums/UserType";
export default {
  name: "UserHighlightedCompanies",
  data: () => ({
    userTypeEnum: UserTypeEnum,
    apiService: new ApiService(),
    loading: false,
    companies: [],
  }),
  components: {
    UserPreferences,
  },
  computed: {},
  props: {
    User: Object,
  },

  async created() {
    this.get_companies();
  },

  methods: {
    savePreferences() {
      this.loading = true;
      this.apiService
        .postRequest("investor/update-preferences", this.User)
        .then((resp) => {
          this.loading = false;
          this.$toast.success(`${this.$t("preference_change_successful")}`);
          this.$emit("saved");
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(`${this.$t(`an_error_occurred`)}`);
        });
    },
    get_companies() {
      this.loading = true;
      this.apiService
        .getRequest("investor/highlighted-companies/" + this.User.Id)
        .then((resp) => {
          this.companies = JSON.parse(resp);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    refresh_company_highlights(companyIds) {
      this.companies.map((x) => {
        if (companyIds.includes(x.Company.CompanyId)) {
          x.IsHighlighted = true;
        } else {
          x.IsHighlighted = false;
        }
      });
    },
    highlight_company(c) {
      c.Loading = true;
      let bodyRequest = {
        UserId: this.User.Id,
        CompanyId: c.Company.CompanyId,
      };

      let request = c.IsHighlighted
        ? this.apiService.deleteRequest(
            "investor/highlight-company",
            bodyRequest
          )
        : this.apiService.postRequest(
            "investor/highlight-company",
            bodyRequest
          );

      request
        .then((resp) => {
          c.IsHighlighted = !c.IsHighlighted;
          c.Loading = false;

          this.$toast.success(
            `${this.$t(resp.message)} ${c.Company.Name} ${this.$t(
              "as_a_highlight"
            )}`
          );
        })
        .catch((error) => {});
    },
    format_to_currency: function (value) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(value);
    },
  },
};
</script>

<style scoped>
.info-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--dark);
}

.highlighted-companies {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.highlighted-companies-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.highlighted-companies-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}
</style>
