<template>
  <div>
    <div class="items-center" v-if="loading">
      <v-progress-circular
        indeterminate
        size="60"
        color="primary"
      ></v-progress-circular>
    </div>
    <div class="card" v-else>
      <v-form
        class="form"
        v-model="valid"
        ref="form"
        @submit.stop.prevent="nexStep"
      >
        <div>
          <div v-if="editable">
            <div class="portfolio-size-container">
              <div
                class="portfolio-size-currency-container"
                v-if="userType === UserTypeEnum.Admin"
              >
                <span class="portfolio-size-currency-label">{{
                  $t("currency")
                }}</span>
                <v-select
                  :rules="[required()]"
                  v-model="User.CurrencyPreference"
                  :items="currencyOptions"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  class="portfolio-size-currency-input"
                  color="primary"
                  @change="changePortfolioSizeCurrency"
                ></v-select>
              </div>
              <div class="portfolio-size-content">
                <span class="section-title">{{
                  $t("yearly_investment_question")
                }}</span>
                <span class="min-value">{{
                  $t("min_value", {
                    value: getMinInvestmentValue(),
                  })
                }}</span>
                <money
                  data-test="Alpha:Preferences:InvestValue"
                  style="padding-left: 10px"
                  :prefix="portfolioValueInputPrefix()"
                  thousands="."
                  @change.native="putPortfolioValue(User.InvestValue)"
                  decimal=","
                  min="1"
                  :rules="[(v) => v >= portfolioMinInvestValue || required()]"
                  :class="[
                    User.InvestValue > portfolioMinInvestValue
                      ? 'field'
                      : 'fieldRequired',
                  ]"
                  v-model="User.InvestValue"
                  class="portfolio-size-input-money"
                >
                </money>
              </div>
            </div>
            <span
              v-if="this.showNotEnoughMessage"
              style="
                font-size: 13px;
                display: flex;
                color: red;
                margin-left: 20px;
                margin-top: 4px;
              "
              data-test="Alpha:UserPreferences:SpanNotEnough"
              >{{
                $t("not_enough", {
                  value: `${PrivatePartner ? "50k" : "200k"}`,
                })
              }}</span
            >
          </div>
          <div v-else>
            <span class="preference-portfolio-value-non-editable">{{
              $t("preferences_portfolio_value")
            }}</span>

            <span class="preference-portfolio-value-non-editable">{{
              formatToCurrency(User.CurrencyPreference, User.InvestValue)
            }}</span>
          </div>
          <div class="section">
            <div class="section-header">
              <span class="section-title">
                {{ $t("sectors_interest") }}
              </span>
              <span class="section-subtitle">{{
                $t("select_all_you_want")
              }}</span>
            </div>
            <v-checkbox
              data-test="Alpha:Preferences:InvestmentsSectors:NoPreference"
              class="no-preference black-label mt-2 pa-0"
              dense
              filled
              hide-details
              v-model="noPreferenceSector"
              @change="noPreferenceSectorsCheckboxChanged"
            >
              <template v-slot:label>
                <span class="checkbox-label">{{ $t("no_preferences") }}</span>
              </template>
            </v-checkbox>
            <v-checkbox
              v-for="(checkbox, index) in investmentsSectors"
              :data-test="`Alpha:Preferences:InvestmentsSectors:${checkbox.text}`"
              :key="index"
              class="black-label mt-2 pa-0"
              dense
              filled
              :disabled="checkbox.disabled"
              :rules="[
                ((v) =>
                  investmentsSectors.filter((x) => x.checked).length != 0) ||
                  required,
              ]"
              v-model="checkbox.checked"
              style="color: var(--primary)"
            >
              <template v-slot:label>
                <span class="checkbox-label">{{ $t(checkbox.text) }}</span>
              </template>
            </v-checkbox>
          </div>
        </div>
        <div>
          <div class="company-size">
            <div class="section-header">
              <span class="section-title">
                {{ $t("company_size_question") }}
              </span>
            </div>
            <v-radio-group
              v-model="User.CompanyRevenue"
              :rules="[required()]"
              data-test="Alpha:UserPreferences:DivCompanySize"
            >
              <v-radio
                v-for="(size, index) in companySize"
                :key="index"
                :value="size.value"
                :data-test="`Alpha:Preferences:CompanyRevenue:${size.text}`"
              >
                <template v-slot:label>
                  <div class="checkbox-container">
                    <span class="checkbox-label">{{ $t(size.text) }}</span>
                    <span class="checkbox-subtitle">
                      {{ $t(size.subtitle) }}</span
                    >
                    <span class="checkbox-recommended" v-if="size.recommended">
                      {{ $t("recomended") }}
                    </span>
                  </div>
                </template>
              </v-radio>
            </v-radio-group>
          </div>
          <div class="investor-type">
            <div class="section-header">
              <span class="section-title">
                {{ $t("investment_desire") }}
              </span>
              <span class="section-subtitle">{{
                $t("select_all_you_want")
              }}</span>
            </div>
            <v-checkbox
              v-for="(checkbox, index) in investIntendedPersonType"
              :style="
                disabledCheckBoxPersonType(checkbox) && 'color: var(--primary)'
              "
              :disabled="disabledCheckBoxPersonType(checkbox)"
              :key="index"
              class="black-label mt-2 pa-0"
              dense
              filled
              :rules="[
                ((v) =>
                  investIntendedPersonType.filter((x) => x.checked).length !=
                  0) || required,
              ]"
              hide-details
              :data-test="`Alpha:Preferences:InvestmentDesire:${checkbox.text}`"
              v-model="checkbox.checked"
              @change="investIntendedPersonTypeChange(checkbox)"
            >
              <template v-slot:label>
                <div class="checkbox-label-flex">
                  <span class="checkbox-label">{{ $t(checkbox.text) }}</span>
                  <span class="checkbox-label-callback">{{
                    $t(checkbox.textCallback)
                  }}</span>
                </div>
              </template>
            </v-checkbox>
            <label
              style="display: block; color: var(--dark)"
              class="mt-8"
              v-if="showCNPJ"
              >{{
                User.Nationality === "Brazil" ? $t("CNPJ") : $t("ein")
              }}</label
            >
            <v-text-field
              v-if="showCNPJ && User.Nationality === 'Brazil'"
              color="dark"
              dense
              style="color: var(--dark)"
              placeholder="00.000.000/0000-00"
              v-model="investorCNPJ"
              v-mask="['##.###.###/####-##']"
              class="my-input-style"
              @change="check_social_number()"
            ></v-text-field>
            <v-text-field
              placeholder="EIN"
              v-if="showCNPJ && User.Nationality !== 'Brazil'"
              color="dark"
              dense
              style="color: var(--dark)"
              v-model="investorCNPJ"
              class="my-input-style"
              @change="check_social_number()"
            ></v-text-field>
            <p
              style="color: red; margin: 0px 0px 0px 12px; font-size: 12px"
              v-if="!!isNotValid && isNotValid.value"
            >
              {{ $t(isNotValid.message) }}
            </p>
            <!-- input do nome fantasia -->
            <label
              style="
                display: block;
                color: var(--dark);
                text-transform: uppercase;
              "
              class="mt-2"
              v-if="showCNPJ"
              >{{ $t("trading_name") }}</label
            >
            <v-text-field
              v-if="showCNPJ"
              color="dark"
              dense
              style="color: var(--dark)"
              v-model="investorCNPJName"
              placeholder="Nome"
              class="my-input-style"
            ></v-text-field>

            <!-- tabela - CNPJ -->
            <v-card
              class="mt-0"
              v-if="
                showCNPJ &&
                InvestorInvestIntendedPersonListPj &&
                InvestorInvestIntendedPersonListPj.length > 0
              "
            >
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="text-center">
                      {{
                        User.Nationality === "Brazil" ? $t("CNPJ") : $t("ein")
                      }}
                    </th>
                    <th class="text-center">{{ $t("trading_name") }}</th>
                    <th class="text-center">{{ $t("edit") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in InvestorInvestIntendedPersonListPj"
                    :key="item"
                  >
                    <td class="text-center">
                      {{ formatCnpj(item.SocialNumberOfInvestmentVehicle) }}
                    </td>
                    <td class="text-center">
                      {{ item.TradingName ? item.TradingName : "-" }}
                    </td>
                    <td class="text-center">
                      <v-btn
                        class="px-2 ml-1 secondary"
                        min-width="0"
                        small
                        @click="handleEditCNPJ(item)"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card>
          </div>
          <div class="card-actions">
            <DxaButton
              data-test="Alpha:Preferences:BtnNextStep"
              class="ma-0 mt-5 next-btn"
              :title="$t(`${btnText}`)"
              style="width: 300px"
              @function="checkForm()"
              :loading="loading"
            ></DxaButton>
          </div>
          <v-dialog persistent max-width="450" v-model="socialNumberDialog"
            ><v-card
              style="
                border-radius: 10px;
                background-color: var(--white) !important;
              "
            >
              <div class="social-modal-header">
                <v-icon color="black" @click="socialNumberDialog = false"
                  >mdi-close</v-icon
                >
              </div>
              <h2 style="color: var(--primary); text-align: center">
                {{ $tc("social_number_already_exists", 2) }}
              </h2>
              <p class="px-12 text-center" style="color: var(--dark)">
                {{ $tc("if_social_number_is_yours", 2) }}
              </p>
              <div style="display: flex; justify-content: center">
                <v-btn
                  outlined
                  style="
                    border-radius: 10px;
                    border-style: groove;
                    border-color: var(--primary);
                    background-color: var(--white);
                    color: var(--primary);
                  "
                  height="35"
                  width="124"
                  @click="goToLogin"
                  class="mb-5"
                  >{{ $t("login") }}</v-btn
                >
              </div>
            </v-card></v-dialog
          >

          <!-- dialog para editar CNPJ  -->
          <v-dialog
            v-model="openDialogEditCNPJ"
            v-if="openDialogEditCNPJ && showCNPJ"
          >
            <v-card :class="gs.isMobile() ? '' : 'dxa_modal'">
              <v-card-title class="mb-2">
                <h4 class="dxa_modal_title h4">
                  {{
                    `${$t("edit")} ${
                      CNPJToEdit.tradingName ? CNPJToEdit.tradingName : ""
                    }`
                  }}
                </h4>
              </v-card-title>
              <v-card-text>
                <v-form
                  @submit.stop.prevent="editCNPJSubmit"
                  ref="formEditCNPJ"
                >
                  <v-col class="mb-2">
                    <v-row>
                      <v-text-field
                        v-if="User.Nationality === 'Brazil'"
                        color="dark"
                        dense
                        style="color: var(--dark)"
                        :rules="[required()]"
                        placeholder="00.000.000/0000-00"
                        v-model="CNPJToEdit.CNPJ"
                        v-mask="['##.###.###/####-##']"
                        :label="$t('CNPJ')"
                        class="my-input-style"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        color="dark"
                        dense
                        style="color: var(--dark)"
                        :rules="[required()]"
                        placeholder="EIN"
                        v-model="CNPJToEdit.CNPJ"
                        :label="$t('ein')"
                        class="my-input-style"
                      ></v-text-field>
                    </v-row>
                  </v-col>

                  <v-col>
                    <v-row>
                      <v-text-field
                        :rules="[required()]"
                        v-model="CNPJToEdit.tradingName"
                        :label="$t('trading_name')"
                        class="pt-0"
                        color="dark"
                        dense
                        style="color: var(--dark)"
                      />
                    </v-row>
                  </v-col>
                  <v-card-actions class="pl-0 dxa_modal_actions">
                    <v-btn
                      class="dxa_modal_btnSuccess"
                      color="primary"
                      min-width="100"
                      :loading="loading"
                      type="submit"
                      >{{ $t("save") }}</v-btn
                    >
                    <v-btn
                      class="dxa_modal_btnError"
                      color="red"
                      min-width="100"
                      @click="openDialogEditCNPJ = false"
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
                <v-alert
                  class="ma-2"
                  v-if="alertDialogAddCNPJ"
                  :type="alertDialogAddCNPJType"
                  >{{ $t(alertDialogAddCNPJ) }}</v-alert
                >
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </v-form>
      <!-- dialog de error quando tentar salvar o cnpj sem estar preenchido! -->
      <v-dialog max-width="564" v-model="errorRequiredData">
        <AlertModalRequiredData
          @closeDialog="errorRequiredData = false"
          :requiredDataList="errorRequiredDataItems"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import DxaButton from "@/components/dxa/Button/DxaButton.vue";
import { getCheckedValues } from "@/shared/helpers/getCheckedValues";
import ValidationServices from "@/services/ValidationService";
import { InvestIntendedPersonTypeEnum } from "@/shared/enums/InvestIntendedPersonTypeEnum";
import { removeFormatCnpj, formatCnpj } from "@/shared/helpers/cnpjHelper";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import GeneralServices from "@/services/GeneralServices";
import AlertModalRequiredData from "@/components/dxa/AlertError/AlertModalRequiredData";

export default {
  name: "UserPreferences",
  components: {
    DxaButton,
    AlertModalRequiredData,
  },
  props: {
    User: Object,
    btnText: String,
    loading: Boolean,
    editable: Boolean,
    userType: Number,
    PrivatePartner: { type: Boolean, default: false },
  },
  created() {
    // garantindo que as mudanças só serão feitas no componente usado no investidor
    if (this.userType === UserTypeEnum.Investor) {
      if (this.User.Nationality !== "Brazil") {
        this.portfolio_size = this.portfolio_size_USD;
      } else {
        this.portfolio_size = this.portfolio_size_BRL;
      }

      // por default o campo de valor já vem preenchido de acordo com o tipo de parceiro
      if (this.editable) {
        this.PrivatePartner
          ? (this.User.InvestValue = 50000)
          : (this.User.InvestValue = 200000);
      }

      // como pf vem sempre marcado tem que chamar a função para fazer o change e popular o objeto user
      // quando a InvestorInvestIntendedPersonList não existir, significa que estamos no cadastro do Investidor.
      // pois no profile ele sempre vem uma array, mesmo que vazia.
      // No cadastro ele pode vir undefined, então vamos criar uma array.
      if (
        this.User.InvestorInvestIntendedPersonList &&
        this.User.InvestorInvestIntendedPersonList.length > 0
      ) {
        const findPersonOffShore =
          this.User.InvestorInvestIntendedPersonList.find(
            (person) =>
              person.InvestIntendedPersonId ===
              InvestIntendedPersonTypeEnum.OFFSHORE
          );

        const findPersonPF = this.User.InvestorInvestIntendedPersonList.find(
          (person) =>
            person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PF
        );

        const findPersonPJ = this.User.InvestorInvestIntendedPersonList.find(
          (person) =>
            person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PJ
        );

        // remove a opção OFFSHORE quando for investidor internacional
        // e não for um investidor antigo que tenha marcado OFFSHORE, assim garantimos que não vamos quebrar os antigos
        // somente remover essa opção para os novos.
        if (!findPersonOffShore && this.User.Nationality !== "Brazil") {
          this.updateInvestIntendedPersonTypeOptions(
            this.InvestIntendedPersonTypeEnum.OFFSHORE
          );
        }

        if (!findPersonPF) {
          this.investIntendedPersonTypeChange(this.investIntendedPersonType[0]);
        }

        if (findPersonPJ) {
          this.showCNPJ = true;
        }
      } else {
        // remove a opção OFFSHORE quando for investidor internacional
        if (this.User.Nationality !== "Brazil") {
          this.updateInvestIntendedPersonTypeOptions(
            this.InvestIntendedPersonTypeEnum.OFFSHORE
          );
        }

        this.User.InvestorInvestIntendedPersonList = [];
        this.investIntendedPersonTypeChange(this.investIntendedPersonType[0]);
      }
    }

    if (this.userType === UserTypeEnum.Admin) {
      this.changePortfolioSizeCurrency();
    }

    this.getInvestorProfile();
    this.getInvestmentSectors();
    this.checkInvestorPersonPJ();
  },
  data() {
    return {
      socialNumberDialog: false,
      vs: new ValidationServices(),
      gs: new GeneralServices(),
      valid: true,
      noPreferenceSector: false,
      minInvestmentValue: " 200.000,00",
      minInvestmentValuePrivatePartner: " 50.000,00",
      openDialogEditCNPJ: false,
      errorRequiredData: null,
      requiredTradingName: false,
      alertDialogAddCNPJ: null,
      alertDialogAddCNPJType: "error",
      formatCnpj,
      CNPJToEdit: {
        CNPJ: null,
        tradingName: null,
        Id: null,
      },
      portfolio_size_BRL: [
        { text: "R$200K", value: 200000 },
        { text: "R$500K", value: 500000 },
        { text: "R$1M", value: 1000000 },
        { text: "R$2M", value: 2000000 },
        { text: "R$3M", value: 3000000 },
        { text: "R$4M", value: 4000000 },
        { text: "R$5M", value: 5000000 },
      ],
      portfolio_size_USD: [
        { text: "U$200K", value: 200000 },
        { text: "U$500K", value: 500000 },
        { text: "U$1M", value: 1000000 },
        { text: "U$2M", value: 2000000 },
        { text: "U$3M", value: 3000000 },
        { text: "U$4M", value: 4000000 },
        { text: "U$5M", value: 5000000 },
      ],
      portfolio_size: [
        { text: "R$200K", value: 200000 },
        { text: "R$500K", value: 500000 },
        { text: "R$1M", value: 1000000 },
        { text: "R$2M", value: 2000000 },
        { text: "R$3M", value: 3000000 },
        { text: "R$4M", value: 4000000 },
        { text: "R$5M", value: 5000000 },
      ],
      investmentsSectors: [
        { text: "Tech", value: 2, checked: false, disabled: false },
        { text: "Pet", value: 5, checked: false, disabled: false },
        { text: "Logistic", value: 4, checked: false, disabled: false },
        { text: "Food", value: 7, checked: false, disabled: false },
        { text: "Health", value: 6, checked: false, disabled: false },
        { text: "Consumption", value: 9, checked: false, disabled: false },
      ],
      investIntendedPersonType: [
        {
          text: "pf",
          textCallback: "person_type_label_callback_pf",
          value: InvestIntendedPersonTypeEnum.PF,
          checked: true,
        },
        {
          text: "pj",
          textCallback: "person_type_label_callback_pj",
          value: InvestIntendedPersonTypeEnum.PJ,
          checked: false,
        },
        {
          text: "OffShore",
          textCallback: "person_type_label_callback_offshore",
          value: InvestIntendedPersonTypeEnum.OFFSHORE,
          checked: false,
        },
      ],
      companySize: [
        {
          text: "startups",
          subtitle: "companies_do_not_have_billing",
          value: 1,
          checked: false,
          recommended: false,
        },
        {
          text: "smes",
          subtitle: "companies_earn_up_to_1M",
          value: 2,
          checked: false,
          recommended: true,
        },
        {
          text: "scale_ups",
          subtitle: "companies_earn_up_to_5M",
          value: 3,
          checked: false,
          recommended: false,
        },
      ],
      showNotEnoughMessage: false,
      showCNPJ: false,
      investorCNPJ: null,
      investorCNPJName: null,
      validCNPJData: true,
      formatToCurrency: formatCurrency,
      portfolioMinInvestValue: this.PrivatePartner ? 50000 : 200000,
      InvestorInvestIntendedPersonListPj: [],
      alertRequiredNameAndNumberCNPJ: false,
      errorRequiredDataItems: [],
      currencyOptions: [
        {
          text: "R$",
          value: CurrencyEnum.BRL,
        },
        {
          text: "US$",
          value: CurrencyEnum.USD,
        },
      ],
      CurrencyEnum: CurrencyEnum,
      UserTypeEnum: UserTypeEnum,
      InvestIntendedPersonTypeEnum: InvestIntendedPersonTypeEnum,
    };
  },
  methods: {
    updateInvestIntendedPersonTypeOptions(InvestIntendedEnumToRemove) {
      this.investIntendedPersonType = this.investIntendedPersonType.filter(
        (option) => option.value !== InvestIntendedEnumToRemove
      );
    },
    getMinInvestmentValue() {
      let minValue = this.minInvestmentValue;

      if (this.PrivatePartner) {
        minValue = this.minInvestmentValuePrivatePartner;
      }

      return `${this.portfolioValueInputPrefix() + minValue}`;
    },
    disabledCheckBoxPersonType(checkbox) {
      let disabled = false;

      if (
        checkbox.value === this.InvestIntendedPersonTypeEnum.PF &&
        (this.userType === UserTypeEnum.Investor ||
          this.userType === UserTypeEnum.Admin)
      ) {
        disabled = true;
      } else {
        disabled = false;
      }

      return disabled;
    },
    getInvestValueMinValueRule(value) {
      if (this.userType === UserTypeEnum.Investor) {
        return value >= this.portfolioMinInvestValue;
      }
      return true;
    },
    getInvestmentSectors() {
      this.investmentsSectors.map((investmentSector, index) => {
        const hasSector = this.User.SectorsList.some((sector) => {
          return sector === investmentSector.value;
        });
        if (hasSector) {
          investmentSector.checked = true;
        }
      });
    },
    async verifyTradingNameNotEmpty() {
      const onlyPj = await this.User.InvestorInvestIntendedPersonList.filter(
        (person) => {
          return (
            person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PJ
          );
        }
      );

      if (onlyPj && onlyPj.length > 0) {
        const findPersonPjNotTradingName = await onlyPj.find((person) => {
          return person.TradingName === null;
        });

        if (findPersonPjNotTradingName) {
          const errorsKeys = ["trading_name"];
          this.investorCNPJ = null;
          this.investorCNPJName = null;
          this.requiredTradingName = true;
          this.CNPJToEdit.CNPJ =
            findPersonPjNotTradingName.SocialNumberOfInvestmentVehicle;
          this.CNPJToEdit.tradingName = null;
          this.CNPJToEdit.Id = findPersonPjNotTradingName.Id;
          this.alertDialogAddCNPJType = "info";
          this.alertDialogAddCNPJ = "not_saved_trading_name_alert";
          this.openDialogEditCNPJ = true;
          // this.errorRequiredData = true;
          // this.errorRequiredDataItems = errorsKeys;
          return true;
        }
      }

      this.requiredTradingName = false;
      return true;
    },
    getInvestorProfile() {
      // desabilitar o pf que vem por padrão checado somente quando não for cadastro do investidor
      if (!this.userType === UserTypeEnum.Investor) {
        this.investIntendedPersonType[0].checked = false;
      }

      this.investIntendedPersonType.map((investor) => {
        if (this.User.InvestorInvestIntendedPersonList) {
          const investIntendedPersonTypesSelected =
            this.User.InvestorInvestIntendedPersonList.some((type) => {
              return type.InvestIntendedPersonId === investor.value;
            });
          if (investIntendedPersonTypesSelected) {
            investor.checked = true;
          }
        }
      });
    },
    required() {
      return (value) => !!value || this.$t("required");
    },
    getSectorsList() {
      const sectorsList = [];
      this.investmentsSectors.map((sector) => {
        if (sector.checked) {
          sectorsList.push(sector.value);
        }
      });
      return sectorsList;
    },
    async checkUserCNPJ() {
      if (this.showCNPJ) {
        // validando se o cnpj e o trandingName foram preenchidos
        if (this.investorCNPJ) {
          const cleanCNPJ = removeFormatCnpj(this.investorCNPJ); // length = 14 para passar
          const errorsKeys = ["CNPJ", "trading_name"];

          // CNPJ precisa de length 14
          if (cleanCNPJ.length < 14 && this.User.Nationality === "Brazil") {
            this.errorRequiredData = true;
            this.validCNPJData = false;
            this.errorRequiredDataItems = errorsKeys;
            return;
          }

          // nome fantasia precisa ser preenchido para salvar o CNPJ
          if (!this.investorCNPJName) {
            this.errorRequiredData = true;
            this.validCNPJData = false;
            this.errorRequiredDataItems = errorsKeys;
            return;
          }
        }

        // lógica para add CNPJ
        if (this.investorCNPJ && this.investorCNPJName) {
          await this.User.InvestorInvestIntendedPersonList.push({
            InvestIntendedPersonId: InvestIntendedPersonTypeEnum.PJ,
            SocialNumberOfInvestmentVehicle: this.investorCNPJ,
            TradingName: this.investorCNPJName,
          });

          // removendo o mask do CNPJ
          await this.User.InvestorInvestIntendedPersonList.forEach((person) => {
            if (
              person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PJ
            ) {
              person.SocialNumberOfInvestmentVehicle = removeFormatCnpj(
                person.SocialNumberOfInvestmentVehicle
              );
            }
          });
        }

        this.validCNPJData = true;
      } else {
        const findItemPF = this.User.InvestorInvestIntendedPersonList.find(
          (person) =>
            person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PF
        );

        if (findItemPF) {
          this.User.InvestorInvestIntendedPersonList = [findItemPF];
        }
      }
    },
    async editCNPJSubmit() {
      let result = this.$refs.formEditCNPJ.validate();

      if (result) {
        // editando o valor do objeto user.
        await this.User.InvestorInvestIntendedPersonList.forEach((item) => {
          if (item.Id === this.CNPJToEdit.Id) {
            item.SocialNumberOfInvestmentVehicle = this.CNPJToEdit.CNPJ;
            item.TradingName = this.CNPJToEdit.tradingName;
          }
        });

        // submit
        this.$emit("btnConfirm");

        // reset dos valores + fechando dialog.
        this.CNPJToEdit.CNPJ = null;
        this.CNPJToEdit.Id = null;
        this.CNPJToEdit.tradingName = null;
        this.openDialogEditCNPJ = false;
      } else {
        return;
      }
    },
    handleEditCNPJ(item) {
      this.CNPJToEdit.CNPJ = item.SocialNumberOfInvestmentVehicle;
      this.CNPJToEdit.tradingName = item.TradingName;
      this.CNPJToEdit.Id = item.Id;
      this.openDialogEditCNPJ = true;
    },
    async checkForm() {
      if (
        this.userType === UserTypeEnum.Investor &&
        this.editable &&
        this.User.InvestValue < this.portfolioMinInvestValue
      ) {
        this.showNotEnoughMessage = true;
        return;
      } else {
        this.showNotEnoughMessage = false;
      }
      let result = this.$refs.form.validate();
      this.User.Sectors = getCheckedValues(this.investmentsSectors);
      this.User.SectorsList = this.getSectorsList();

      // quando o usuário já tiver um CNPJ cadastrado, mas não colocou tradingName, ele precisa por para salvar.
      await this.verifyTradingNameNotEmpty();
      if (this.requiredTradingName) return;

      // validando se o CNPJ e nome fantasia estão preenchidos + push na array.
      await this.checkUserCNPJ();
      if (!this.validCNPJData) return;

      this.User.InvestmentVehicle = getCheckedValues(
        this.investIntendedPersonType
      );
      if (result) {
        this.$emit("btnConfirm");
      } else {
        this.$toast.error(this.$t("an_error_ocurred"));
      }
    },

    checkInvestorPersonPJ() {
      const hasPj = this.User.InvestorInvestIntendedPersonList.some(
        (person) => {
          const personPj =
            person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PJ;
          if (personPj && person.SocialNumberOfInvestmentVehicle) {
          }

          return personPj;
        }
      );

      if (hasPj) {
        // pegando todos os pj
        const getOnlyPj = this.User.InvestorInvestIntendedPersonList.filter(
          (person) => {
            return (
              person.InvestIntendedPersonId === InvestIntendedPersonTypeEnum.PJ
            );
          }
        );

        if (getOnlyPj && getOnlyPj.length > 0) {
          this.InvestorInvestIntendedPersonListPj = getOnlyPj;
        }
      }

      if (hasPj) {
        this.showCNPJ = true;
      }
    },
    putPortfolioValue(value) {
      this.User.InvestValue = value;
    },
    noPreferenceSectorsCheckboxChanged() {
      if (this.noPreferenceSector) {
        this.investmentsSectors = this.investmentsSectors.map(function (x) {
          x.checked = true;
          x.disabled = true;
          return x;
        });
      } else {
        this.investmentsSectors = this.investmentsSectors.map(function (x) {
          x.checked = false;
          x.disabled = false;
          return x;
        });
      }
    },
    async check_social_number() {
      if (this.User.Nationality !== "Brazil") {
        this.isNotValid = null;
        return;
      }

      if (!this.vs.cnpj_test(this.investorCNPJ)) {
        this.isNotValid = { message: "invalid_cnpj", value: true };
        return;
      } else {
        this.isNotValid = null;
      }
    },
    investIntendedPersonTypeChange(type) {
      if (type.checked) {
        if (!this.User.InvestorInvestIntendedPersonList) {
          this.User.InvestorInvestIntendedPersonList = [];
        }
        if (type.value === InvestIntendedPersonTypeEnum.PJ) {
          this.showCNPJ = true;
        } else {
          this.User.InvestorInvestIntendedPersonList.push({
            InvestIntendedPersonId: type.value,
            SocialNumberOfInvestmentVehicle: null,
            TradingName: null,
          });
          this.investorCNPJ = null;
          this.investorCNPJName = null;
        }
      } else {
        // vai remover do array, se desmarcou
        const index = this.User.InvestorInvestIntendedPersonList.findIndex(
          (obj) => obj.InvestIntendedPersonId === type.value
        );
        this.User.InvestorInvestIntendedPersonList.splice(index, 1);
        if (type.value === InvestIntendedPersonTypeEnum.PJ) {
          this.showCNPJ = false;
        }
      }
    },
    goToLogin() {
      this.$router.push("/auth");
    },
    portfolioValueInputPrefix() {
      let prefix;

      // verificação quando está usando o campo como admin
      if (this.userType === UserTypeEnum.Admin) {
        if (this.User.CurrencyPreference === CurrencyEnum.BRL) {
          prefix = "R$";
        } else {
          prefix = "US$";
        }
      } else if (this.userType === UserTypeEnum.Investor) {
        if (this.User.Nationality === "Brazil") {
          prefix = "R$";
        } else {
          prefix = "US$";
        }
      } else {
        prefix = "R$";
      }

      return prefix;
    },
    changePortfolioSizeCurrency() {
      if (this.User.CurrencyPreference === CurrencyEnum.BRL) {
        this.portfolio_size = this.portfolio_size_BRL;
      } else {
        this.portfolio_size = this.portfolio_size_USD;
      }
    },
  },
};
</script>

<style scoped>
.no-preference {
  margin-bottom: 24px;
}

.social-modal-header {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  padding: 8px;
}
.main-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.header-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.form {
  display: flex;
  flex-direction: row;
}

.card {
  padding: 35px;
  gap: 12px;
  background: var(--white);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.section-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.min-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-top: 8px;
}

.fieldRequired {
  width: 60%;
  box-sizing: border-box;
  border-style: none !important;
  height: 40px;
  border-color: red !important;
  border-radius: 4px !important;
}

.field {
  width: 100%;
  box-sizing: border-box;
  border-style: none;
  height: 40px;
  margin-top: 8px;
  border-radius: 4px !important;
}

.portfolio-value-btn {
  border-radius: 8px;
  width: 58px;
  justify-content: center;
  border-style: groove;
  border-color: var(--primary);
  background-color: var(--white) !important;
  color: var(--primary) !important;
}

.primary-bg {
  background-color: var(--primary) !important;
  color: var(--white) !important;
}

.section {
  margin-top: 24px;
  padding: 0 20px;
}

.section-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
}

.investor-type {
  margin-top: 32px;
  padding: 0 20px;
}

.next-btn {
  margin-top: 40px !important;
}

.portfolio-size-content {
  padding: 0 20px;
}

.company-size {
  padding: 0 20px;
}

.black-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.checkbox-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.section-header {
  display: flex;
  flex-direction: column;
}

.checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.checkbox-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #9c9c9c;
  margin-left: 4px;
}

.checkbox-recommended {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #03a768;
  margin-left: 4px;
}

.progress {
  height: 6px;
  background: var(--primary);
  border-radius: 12px;
  width: 50%;
}

.preference-portfolio-value-non-editable {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.portfolio-size-container {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}

.portfolio-size-currency-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: var(--dark);
  margin-bottom: 8px;
  display: block;
}

.portfolio-size-currency-input {
  max-width: 80px;
  max-height: 34px;
  border-radius: 4px;
}

.portfolio-size-currency-container {
  padding-bottom: 6px;
}

.portfolio-size-input-money {
  min-width: 240px;
  background-color: var(--white);
  border: 1px solid #aaaaaa !important;
  color: var(--dark);
}

.checkbox-label-callback {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.checkbox-label-flex {
  display: flex;
  align-items: center;
  gap: 4px;
}

.my-input-style >>> .v-text-field__slot input {
  color: var(--dark);
}

@media (max-width: 768px) {
  .card {
    width: 100%;
    flex-direction: column;
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .header-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }

  .items-center {
    display: grid;
    place-items: center;
    margin-top: 60px;
  }

  .content {
    width: 100%;
    flex-direction: column;
  }

  .header-subtitle {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.15px;
    color: #9c9c9c;
  }

  .header-title {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.15px;
    color: var(--dark);
    margin-bottom: 8px;
  }

  .step-container {
    display: none;
  }

  .section-title {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    color: var(--dark);
  }

  .min-value {
    margin: 8px 0;
  }

  .field,
  .fieldRequired {
    width: 100%;
  }

  .section-subtitle {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.15px;
    color: #9c9c9c;
    margin-bottom: 20px;
  }

  .card-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .checkbox-subtitle {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.15px;
    color: #9c9c9c;
    margin-left: 0;
  }

  .checkbox-recommended {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.15px;
    color: #03a768;
    margin-left: 0;
  }

  .form {
    flex-direction: column;
  }

  .progress {
    display: none;
  }

  .portfolio-size-input-money {
    min-width: initial;
  }

  .checkbox-label-flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
}
</style>
